<template>

    <div class="w-full h-full px-3 py-4">

        <div class="h-auto w-full rounded-lg shadow_box flex flex-col p-2 mb-6 relative" :class="'bg-module-'+mode">

            <div v-if="error" class="absolute h-full w-full top-0 left-0 flex flex-col justify-center items-center z-30" style="backdrop-filter:blur(5px)">
                <span class="text-lg text-white">{{error}}</span>
                <div class="h-8 w-1/3 rounded bg-red flex flex-row justify-center items-center cursor-pointer shadow" @click="error = false">
                    <span class="text-white">Aceptar</span>
                </div>
            </div>

            <div v-if="user.rol.Name == 'Representante'" class="h-auto">
            
                <All model="RepresentantRelations" :immediate="true" :data.sync="representants" :query="queryrelations" v-slot="{data:relations,loading:relationsloading}">

                    <div v-if="!relationsloading" class="h-auto">

                        <div class="h-auto w-full px-2 mt-6 flex flex-col justify-center items-start">
                            
                            <span class="text-md font-medium mb-2" :class="'text-dfont-'+mode" >{{$t('representant')}}</span>

                            <div class="h-8 w-full">

                                <div class="h-full w-full rounded-lg flex flex-row justify-between items-center" :class="'bg-box-'+mode">
                                
                                    <select name="representantFilter" v-model="representantFilter" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                        <option v-for="(el,index) in relations" :key="index" :value="el.Id" :class="'text-dfont-'+mode">{{el.Name}}</option>
                                    </select>

                                </div>

                            </div>

                        </div>

                        <div v-if="companies" class="h-auto w-full px-2 mt-6 flex flex-col justify-center items-start">
                            
                            <span class="text-md font-medium mb-2" :class="'text-dfont-'+mode" >{{$t('company')}}</span>

                            <div class="h-8 w-full">

                                <div class="h-full w-full rounded-lg flex flex-row justify-between items-center" :class="'bg-box-'+mode">

                                    <select name="companyfilter" v-model="companyfilter" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                        <option v-for="(el,index) in companies" :key="index" :value="el.Id" :class="'text-dfont-'+mode">{{el.Name}}</option>
                                        <option v-if="companies.length > 1" :value="null">TODAS</option>
                                    </select>

                                </div>

                            </div>
                        
                        </div>
                        
                    </div>

                </All>

            </div>

            <div class="h-auto w-full px-2 mt-6 flex flex-col justify-center items-start">
                <span class="text-md font-medium mb-2" :class="'text-dfont-'+mode" >{{$t('period')}}</span>
                <div class="h-8 w-full">
                    <div class="h-full rounded-lg flex flex-row justify-between items-center" :class="'bg-box-'+mode">
                    
                        <select name="period" v-model="period" class="h-full w-full px-2" :class="'bg-box-'+mode+' text-dfont-'+mode">
                            <option v-for="(el,index) in periods" :key="index" :value="el.value" :class="'text-dfont-'+mode">{{el.name}}</option>
                        </select>

                    </div>
                </div>
            </div>
            <filter-months v-if="period == 'month'"   :mode='mode'/>

            <div class="h-10 mt-12 px-12">
                <div class="h-full rounded-lg bg-red flex flex-row justify-center items-center cursor-pointer" @click="applyFilters()">
                    <span class="text-lg text-white">{{$t('apply')}}</span>
                </div>
            </div>
            
            <div class="h-6"></div>

        </div>

    </div>

</template>

<script>
import { All } from '@/api/components';
import { state, actions } from '@/store';

export default {
    components:{
        All
    },
    data(){
        return{
            periods: [
                { 
                    name: this.$t("monthly"),
                    value: 'month'
                },
                { 
                    name: this.$t("quarterly"),
                    value: 'quarter'
                },
                { 
                    name: this.$t("annual"),
                    value: 'year'
                }
            ],
            period: '',
            zoneFilter: null,
            representantFilter: null,
            companyfilter: null,
            representants: null,
            companies:null,
            error:false
        }
    },
    methods:{
        applyFilters(){

            if(this.companyfilter && this.period && this.representantFilter){

                actions.setPeriod(this.period)

                if(state.user.rol.Name == 'Representante'){

                    actions.setIdEmployeeSelected(this.representantFilter.toLowerCase())

                    if(this.companyfilter){

                        actions.setIdCompany(this.companyfilter.toLowerCase())

                    } else {

                        actions.setIdCompany(this.companyfilter)

                    }
                    

                    let arrayResult = Object.values(this.representants)

                    for (let index = 0; index < arrayResult.length; index++) {

                        if(arrayResult[index].Id == this.representantFilter){
                            actions.setEmployeeName(arrayResult[index].Name)
                        }
                        
                    }

                }

                this.$router.push( { name: 'representante' } );

            } else {
                this.error = 'Seleccione la compañía'
            }

        }
    },
    computed:{
        user(){
            return state.user
        },
        queryrelations(){
            return{
                id: state.user.id
            }
        },
        mode(){
            return state.mode
        }
    },
    watch:{
        representantFilter(n,o){

            if(o != null){
                this.companyfilter = null
            }

            setTimeout(() => {

                for (let index = 0; index < Object.values(this.representants).length; index++) {

                    if(n == Object.values(this.representants)[index].Id){

                        this.companies = Object.values(this.representants)[index].EmployeeCompanies

                    }

                }

            }, 1000);

        }
    },
    mounted(){
        
        this.period = state.period

        this.representantFilter = state.idEmployeeSelected.toUpperCase()
        this.companyfilter = state.idCompany.toUpperCase()
        
    }
}
</script>

<style>

    /* .el-input__inner{
        background-color: rgba(0,0,0,0) !important;
        border: 0;
        color: #2C5358;
    } */

</style>